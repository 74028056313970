@import '../../../common-css/fonts.scss';
@import '../../../common-css/animation.scss';

.aboutMeSection {
    margin: 10% 10%;
    padding: 1% 2%;
    border: 1px solid white;
    border-radius: 50px;

    .title-container {
        height: fit-content;

        .section-title {
            font-size: 32px;
            margin: 2.5% 0;
        }
    }

    .text-container {
        height: fit-content;
        margin: 4% 0;

        .content {
            font-weight: 300;
            font-size: 28px;
        }
    }

    .line {
        display: block;
        border-bottom: solid 2px white;
    }

}