@import "../common-css/fonts.scss";
@import "../common-css/colors.scss";

.scroll-menu {
    padding: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 50px;
    height: 250px;
    border-radius: 10px;
    bottom: 40px;
    right: 20px;
    position: fixed;
}

.item {
    font-family: $raleway;
    position: relative;
    background-color: white;
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: rgba(255, 255, 255, 0.5);
        cursor: pointer;

        .tooltip {
            visibility: visible;
        }
    }

    .icon {
        color: black;
        width: 30px;
        height: 30px;
    }

    .tooltip {
        visibility: hidden;
        text-align: right;
        padding: 5px;
        position: fixed;
        z-index: 1;
        right: 110%;
    }
}


#scroll-anchor {
    opacity: 1;
    position: absolute;
    bottom: 40px;
}

.tippy-box[data-theme~='tomato'] {
    font-family: $raleway;
    background-color: #3d4879;
    color: white;
    border: 5px solid #3d4879;
    border-radius: 5px;
    position: sticky;
}