@import "../../common-css/container.scss";
@import "../../common-css/colors.scss";
@import "../../common-css/fonts.scss";

.about {
    .about-title-container {
        display: flex;
        justify-content: center;

        .about-title {
            width: fit-content;
            margin-top: 12%;
            font-size: 32px;
            border: solid 2px white;
            border-radius: 1rem;
            padding: 1%;
            text-align: center;
        }
    }

    .about-context-container {
        height: 100%;
        margin: 5% 10%;
        border-radius: 20px;
        border-top: 2rem solid gray;
        background-color: $blue;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .about-context {
            .about-list {
                padding-left: 5%;
                margin-top: 1%;
                font-size: 24px;
                list-style: none;

                .about-list-item {
                    margin-top: 2.5%;

                    &::before {
                        content: '>';
                        margin-right: 1.5%;
                    }
                }
            }
        }
    }
}