.experience {
  margin: 5% 10%;

  .experience-header {
    .experience-title {
      font-size: 32px;
    }
  }

  .experience-container {
    margin: 2.5% 0;

    .experience-list {
      list-style-type: none;
      width: fit-content;

      .experience-item {
        margin: 4% 0;

        .job-header-container {
          font-size: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .job-header {
            display: flex;
            align-items: center;
            .link {
              text-decoration: none;
              color: inherit;
              margin-right: 0.5rem;

              .icon {
                background-color: rgb(56, 44, 89);
                padding: 0.5rem;
                border-radius: 0.5rem;

                &:hover {
                  background-color: rgb(82, 77, 98);
                }
              }
            }
            .separator {
              padding: 0 10px;
            }
          }

          .job-date {
            font-size: 20px;
            color: rgb(200, 200, 200);
          }
        }

        .tech-container {
          margin: 1% 1%;
        }

        .job-container {
          .job-list {
            list-style-type: "📌";
            .job-item {
              vertical-align: middle;
              margin: 2% 0;
              .job-item-content {
                display: block;
                padding-left: 0.5%;
              }
            }
          }
        }
      }
    }
  }
}
