.resume {
    margin-right: 10% 10%;
    display: flex;
    justify-content: center;
    align-items: center;

    .resume-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5%;
        border: 1px solid white;
        border-radius: 2rem;
        text-decoration: none;
        color: inherit;

        &:hover {
            background-color: rgb(56, 44, 89);
            color: orange;
        }

        .icon {
            width: 120px;
            height: 120px;
        }
    }
}