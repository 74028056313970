@import "../../common-css/fonts.scss";
@import "../../common-css/container.scss";

.introduction {
    margin: 14.5px 0 0 14.5px;

    .container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .photo {
            border: 5px solid white;
            border-radius: 20px;
            width: 25rem;
            height: 25rem;
        }

        .intro-content-wrapper {
            width: 650px;
            cursor: default;

            .intro-title {
                margin: 0;
                font-size: 72px;
            }

            .intro-content {
                font-size: 32px;
                font-weight: 400;

                ul {
                    li {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}