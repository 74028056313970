@import './components/common-css/colors.scss';
@import "./components/common-css/fonts.scss";


body {
  font-family: $montserrat;
  color: #f5eaea;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: $darkblue;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;

  &:hover {
    background-color: #d3d3eb2d;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  background-clip: content-box;
}