@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap');

$montserrat: 'Montserrat',
    sans-serif;
$raleway: 'Raleway',
    sans-serif;


.highlight {
    color: orange;
}